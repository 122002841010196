<template>
  <div class="pa-2">
    <h1 class="page-title-list t-header">ギフトチケット</h1>
    <div>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="#13ace0"></v-tabs-slider>
        <v-tab
          v-for="(item) in filteredTabs"
          :key="item.to"
          :to="item.to"
          style="font-size: 14px !important"
          class="page-title-tab px-13 pt-3"
          >{{ item.title }}
        </v-tab>
      </v-tabs>
    </div>
    <div class="mt-2">
      <giftTicket v-if="tab==='/gift-ticket/orders'" />
      <PointExchangePorudctList v-if="tab==='/gift-ticket/products'" />
    </div>
  </div>
</template>
<script>
import { filterTabs } from '@/utils/permissions';
import giftTicket from './giftTicket.vue';
import PointExchangePorudctList from './PointExchangePorudctList.vue';

export default {
  components: { giftTicket, PointExchangePorudctList },
  data() {
    return {
      tab: null,
      items: [
        {
          title: 'ギフトチケット一覧',
          to: '/gift-ticket/products',
        },
        {
          title: 'ご注文確認一覧',
          to: '/gift-ticket/orders',
        },
      ],
    };
  },
  watch: {
    '$route.params.tab': {
      immediate: true,
      handler(tab) {
        this.tab = tab ?? this.filteredTabs[0].to;
      },
    },
  },
  computed: {
    filteredTabs() {
      return filterTabs(this.items);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application .primary--text {
  color: #13ace0 !important;
}
.v-slide-group__wrapper {
  .v-tab--active {
    background-color: #ffffff !important;
    color: #13ace0 !important;
  }
}
.v-tab--active:hover {
  background-color: #ceeffb !important;
  color: #13ace0 !important;
}

.v-tab {
  background-color: #ffffff;
  color: #aaaaaa;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.v-tab:hover {
  background-color: #eeeeee;
  color: #aaaaaa;
}
</style>
