<template>
  <v-dialog :value="value" @input="emitInput" persistent max-width="1200">
    <v-card>
      <v-form v-model="valid">
        <v-card-title>
          <div class="d-flex justify-space-between w-100">
            <div>
              <span class="dialog-headline">
                ギフトチケット{{ isNew ? '新規登録' : '更新' }}</span
              >
            </div>
            <div>
              <v-btn v-if="!isNew" color="error" @click="confirmDelete">
                削除
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-container>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> ID </v-col>
                    <v-col cols="8">
                      {{ id }}
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> チケット名 </v-col>
                    <v-col cols="8">
                      <v-text-field v-if="isNew" v-model="form.name" :rules="[$rules.required]" dense />
                      <div v-else>{{form.name}}</div>
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> 会員Web </v-col>
                    <v-col cols="8">
                      <v-checkbox v-model="form.showMember" :rules="[$rules.required]" dense />
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> TVP </v-col>
                    <v-col cols="8">
                      <tvos-int-input allowZero required v-model="form.tvpPoint" :rules="[$rules.required]" :disabled="!isNew" dense />
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> SP </v-col>
                    <v-col cols="8">
                      <tvos-int-input allowZero required v-model="form.spPoint" :rules="[$rules.required]" :disabled="!isNew" dense />
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> FP </v-col>
                    <v-col cols="8">
                      <tvos-int-input allowZero required v-model="form.fpPoint" :rules="[$rules.required]" :disabled="!isNew" dense />
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> メール宛先 </v-col>
                    <v-col cols="8">
                      <v-text-field v-model="form.emailAddress" :rules="[$rules.required]" dense />
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> 会社名 </v-col>
                    <v-col cols="8">
                      <v-text-field v-model="form.companyName" :rules="[$rules.required]" dense />
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> 交換開始日 </v-col>
                    <v-col cols="8">
                      <date-time-picker v-model="form.saleStartAt" dense />
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> 交換終了日</v-col>
                    <v-col cols="8">
                      <date-time-picker v-model="form.saleEndAt" dense />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="6" v-if="!isNew">
                <v-container>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> 登録者 </v-col>
                    <v-col cols="8">
                      {{ data.createdBy && data.createdBy.name }}
                    </v-col>
                  </v-row>
                  <v-row class="input-row">
                    <v-col cols="4" class="label"> 登録日時 </v-col>
                    <v-col cols="8">
                      {{ data.createdAt | formatDateTimeISONoSecond }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="error" @click="close">
            キャンセル
          </v-btn>
          <v-btn color="primary" @click="saveClick" :disabled="!checkPerUser || !canSave">
            {{ isNew ? '登録' : '更新'}}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import DateTimePicker from '@/components/Input/DateTimePicker.vue';

const initForm = () => ({
  name: '',
  showMember: false,
  tvpPoint: null,
  spPoint: null,
  fpPoint: null,
  emailAddress: null,
  companyName: null,
  saleStartAt: null,
  saleEndAt: null,
})

export default {
  components: { DateTimePicker },
  props: {
    value: Boolean,
    id: Number,
    checkPerUser: Boolean
  },
  data () {
    return {
      form: initForm(),
      data: {},
      valid: false
    }
  },
  computed: {
    isNew() {
      return !this.id
    },
    canSave() {
      return this.valid
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler(v) {
        if (v) {
          this.form = initForm();
          this.data = await this.$store.dispatch('getPointExchangeProduct', this.id);
          this.form = {
            ...initForm(),
            name: this.data.name,
            showMember: this.data.showMember,
            tvpPoint: this.data.tvpPoint,
            spPoint: this.data.spPoint,
            fpPoint: this.data.fpPoint,
            emailAddress: this.data.emailAddress,
            companyName: this.data.companyName,
            saleStartAt: this.data.saleStartAt ? new Date(this.data.saleStartAt) : null,
            saleEndAt: this.data.saleEndAt ? new Date(this.data.saleEndAt) : null,
          }
        }
      }
    }
  },
  methods: {
    emitInput(v) {
      this.$emit('input', v)
    },
    close () {
      this.$emit('input', false)
    },
    reloadAndClose() {
      this.$emit('reload', true);
      this.close()
    },
    async saveClick() {
      if (this.isNew) {
      // -- make sure emailAddress is set on created entity
        // call create api
        await this.$store.dispatch('createPointExchangeProduct', this.form);
        this.reloadAndClose()
      } else {
      // --try here
        // call update api
        await this.$store.dispatch('updatePointExchangeProduct', {
          id: this.id,
          ...this.form
        });
        this.reloadAndClose()
      }
    },
    async confirmDelete() {
      // --try here
      this.$confirm({
        title: 'ギフトチケット削除',
        // eslint-disable-next-line no-irregular-whitespace
        message: `${this.data.name}のギフトチケットを削除します。取消はできません。よろしいですか？　※既に申込が存在する場合は削除に失敗します`,
        ok: async () => {
          await this.$store.dispatch('deletePointExchangeProduct', this.id);
          this.reloadAndClose()
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.input-row {
  align-items: baseline;
}
.lable {
  text-align: right;
}
</style>