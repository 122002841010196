<template>
  <div>
    <div class="t-main">
      <Table
        :total="total"
        :attr="{
          dense: true,
          'no-data-text': $t('rules.noData'),
          'item-key': 'id',
        }"
        ref="table"
        :funReset="getData"
        :itemsPerPage="5"
        :itemsPerPageOptions="[5, 30, 40, 50, 100, 200, 500]"
        :headers="headers"
        :items="items"
      >
        <template v-slot:[`item.customerDetail`]="{ item }">
          <v-btn
            class="t-btn--prm"
            icon
            small
            @click="openCustomerDetail(item)"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.client.isCompany`]="{ item }">
          <span v-if="item.client.isCompany === true">法人</span>
          <span v-else>個人</span>
        </template>
        <template v-slot:[`item.point`]="{item}">
          <span>{{ item.point | toThousands }}</span>
        </template>
        <template v-slot:[`item.pointTotal`]="{item}">
          <span>{{ item.point * item.quantity | toThousands }}</span>
        </template>
        <template v-slot:[`item.confirmedAt`]="{ item }">
          <span v-if="item.confirmedAt === null">未開封</span>
          <span v-else>開封済み</span>
        </template>
        <template v-slot:[`item.emailHistorySentAt`]="{ item }">
          <span v-if="item.emailHistory === null">未送信</span>
          <span v-else>{{ formatDate(item.emailHistory.sentAt) }}</span>
          <!-- {{ item.emailHistory && formatDate(item.emailHistory.sentAt) }} -->
        </template>
        <template v-slot:[`item.address`]="{ item }">
          {{ item.prefecture ? item.prefecture : '' }}
          {{ item.locality ? item.locality : '' }}
          {{ item.address1 ? item.address1 : '' }}
          {{ item.address2 ? item.address2 : '' }}
        </template>
        <template v-slot:[`item.toName`]="{ item }">
          <SpanHoverPop :value="item.toName" />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import SpanHoverPop from '@/components/shared/SpanHoverPop.vue';
import Table from '@/components/Table/index.vue';

export default {
  name: 'GiftTicket',
  data() {
    return {
      total: 0,
      headers: [
        {
          text: this.$t('giftTicket.orderNumber'),
          value: 'id',
          align: 'center',
          // sortable: true,
          sortable: false,
        },
        {
          text: this.$t('giftTicket.applicant'),
          value: 'client.name',
          sortable: false,
        },
        {
          text: this.$t('giftTicket.corporateOrIndividual'),
          value: 'client.isCompany',
          sortable: false,
        },
        {
          text: this.$t('webPageManage.infoRequest.customerDetail'),
          value: 'customerDetail',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('giftTicket.applicationService'),
          value: 'pointExchangeProduct.name',
          sortable: false,
        },
        {
          text: 'ポイント種別',
          value: 'pointType',
          sortable: false,
        },
        {
          text: 'ポイント数（商品単価）',
          value: 'point',
          sortable: false,
        },
        {
          text: this.$t('giftTicket.numberOfCase'),
          value: 'quantity',
          sortable: false,
        },
        {
          text: 'ポイント数（合計）',
          value: 'pointTotal',
          sortable: false,
        },
        {
          text: this.$t('giftTicket.ticketIssuerEmailOpenConfirmation'),
          value: 'confirmedAt',
          sortable: false,
        },
        {
          text: this.$t('giftTicket.dateTimeSending'),
          value: 'emailHistorySentAt',
          sortable: false,
        },
        {
          text: '送付先住所',
          value: 'address',
          sortable: false,
        },
        {
          text: '宛名',
          value: 'toName',
          sortable: false,
        },
      ],
      listGiftTicket: [],
    };
  },
  mounted() {
    this.$refs.table.reset();
  },
  computed: {
    ...mapGetters(['getPointExchangeList']),
    items() {
      return this.getPointExchangeList.map(item => {
        return {
          ...item,
          pointType: this.pointTypeSelect(item, 'TVP', 'SP', 'FP', 'その他'),
          point: this.pointTypeSelect(item, item.tvpPoint, item.spPoint, item.fpPoint, '-')
        }
      })
    }
  },
  methods: {
    ...mapActions(['giftTicketList']),
    ...mapMutations(['setPointExchangeList']),
    formatDate(date) {
      return moment(date).format('YYYY/MM/DD HH:mm');
    },

    pointTypeSelect(row, tvp, sp, fp, other) {
      if (!row.contract) return tvp;
      switch (row.contract.productTypeId) {
        case this.$productTypeIds.SP: return sp;
        case this.$productTypeIds.FP: return fp;
        default: return other;
      }
    },

    async getData(variables) {
      if (variables) {
        await this.giftTicketList({
          skip: variables.pagination.skip,
          take: variables.pagination.take,
        }).then(result => {
          this.total = result;
        });
      }
    },

    openCustomerDetail(item) {
      window.open(
        `/customer-details/${item.client.id}?typeClient=${item.client.type}`,
      );
    },
  },
  components: {
    Table,
    SpanHoverPop
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 20px !important;
  color: #000 !important;
  font-weight: bold;
}
h2 {
  font-size: 20px !important;
  color: #0b6786 !important;
  font-weight: bold;
}
.v-data-table::v-deep {
  th,
  .v-select__selection {
    font-size: 14px !important;
    color: #757575 !important;
    font-weight: bold !important;
    white-space: nowrap;
  }
  tbody {
    tr {
      td {
        color: #757575 !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        white-space: nowrap;
        .v-btn__content {
          span {
            color: #fff !important;
          }
        }
      }
    }
  }
  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    .theme--light.v-input {
      .v-input__control {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 13px !important;
        }
      }
    }
    .v-data-footer__pagination {
      display: none !important;
    }
    .v-input__append-inner {
      .v-icon__svg {
        color: #333333 !important;
      }
    }
  }
}
</style>
