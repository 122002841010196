var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"t-main"},[_c('div',{staticClass:"d-flex justify-end mb-2"},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.checkPerUser},on:{"click":_vm.openCreate}},[_vm._v(" 新規作成 ")])],1),_c('div',[_c('v-checkbox',{attrs:{"label":"交換受付期間外のチケットも表示する"},model:{value:(_vm.showNotOnSale),callback:function ($$v) {_vm.showNotOnSale=$$v},expression:"showNotOnSale"}})],1),_c('Table',{ref:"table",attrs:{"total":_vm.total,"attr":{
        dense: true,
        'item-key': 'id',
      },"funReset":_vm.getData,"disablePagination":true,"hideFooter":true,"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('span-hover-pop',{attrs:{"value":item.name}})]}},{key:"item.showMember",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.showMember ? '公開' : '非公開')+" ")]}},{key:"item.tvp",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toThousands")(item.tvpPoint))+" ")]}},{key:"item.sp",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toThousands")(item.spPoint))+" ")]}},{key:"item.fp",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toThousands")(item.fpPoint))+" ")]}},{key:"item.startAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.saleStartAt))+" ")]}},{key:"item.endAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.saleEndAt))+" ")]}},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"t-btn--prm",attrs:{"rounded":"","icon":""},on:{"click":function($event){return _vm.openEdit(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}])}),_c('PointExchangeProductCreateUpdateDialog',{attrs:{"id":_vm.updateId,"checkPerUser":_vm.checkPerUser},on:{"reload":_vm.reload},model:{value:(_vm.createUpdateDialog),callback:function ($$v) {_vm.createUpdateDialog=$$v},expression:"createUpdateDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }