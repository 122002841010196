var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"t-main"},[_c('Table',{ref:"table",attrs:{"total":_vm.total,"attr":{
        dense: true,
        'no-data-text': _vm.$t('rules.noData'),
        'item-key': 'id',
      },"funReset":_vm.getData,"itemsPerPage":5,"itemsPerPageOptions":[5, 30, 40, 50, 100, 200, 500],"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.customerDetail",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"t-btn--prm",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openCustomerDetail(item)}}},[_c('v-icon',[_vm._v("mdi-account")])],1)]}},{key:"item.client.isCompany",fn:function(ref){
      var item = ref.item;
return [(item.client.isCompany === true)?_c('span',[_vm._v("法人")]):_c('span',[_vm._v("個人")])]}},{key:"item.point",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toThousands")(item.point)))])]}},{key:"item.pointTotal",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toThousands")(item.point * item.quantity)))])]}},{key:"item.confirmedAt",fn:function(ref){
      var item = ref.item;
return [(item.confirmedAt === null)?_c('span',[_vm._v("未開封")]):_c('span',[_vm._v("開封済み")])]}},{key:"item.emailHistorySentAt",fn:function(ref){
      var item = ref.item;
return [(item.emailHistory === null)?_c('span',[_vm._v("未送信")]):_c('span',[_vm._v(_vm._s(_vm.formatDate(item.emailHistory.sentAt)))])]}},{key:"item.address",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.prefecture ? item.prefecture : '')+" "+_vm._s(item.locality ? item.locality : '')+" "+_vm._s(item.address1 ? item.address1 : '')+" "+_vm._s(item.address2 ? item.address2 : '')+" ")]}},{key:"item.toName",fn:function(ref){
      var item = ref.item;
return [_c('SpanHoverPop',{attrs:{"value":item.toName}})]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }