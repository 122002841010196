<template>
  <div class="t-main">
    <div class="d-flex justify-end mb-2">
      <v-btn @click="openCreate" color="primary" :disabled="!checkPerUser">
        新規作成
      </v-btn>
    </div>
    <div>
      <v-checkbox label="交換受付期間外のチケットも表示する" v-model="showNotOnSale" />
    </div>
    <Table
        :total="total"
        :attr="{
          dense: true,
          'item-key': 'id',
        }"
        ref="table"
        :funReset="getData"
        :disablePagination="true"
        :hideFooter="true"
        :headers="headers"
        :items="items"
      >
        <template v-slot:item.name="{item}">
          <span-hover-pop :value="item.name" />
        </template>
        <template v-slot:item.showMember="{item}">
          {{ item.showMember ? '公開' : '非公開' }}
        </template>
        <template v-slot:item.tvp="{item}">
          {{ item.tvpPoint | toThousands }}
        </template>
        <template v-slot:item.sp="{item}">
          {{ item.spPoint | toThousands }}
        </template>
        <template v-slot:item.fp="{item}">
          {{ item.fpPoint | toThousands }}
        </template>

        <template v-slot:item.startAt="{item}">
          {{ item.saleStartAt | formatDateTime }}
        </template>
        <template v-slot:item.endAt="{item}">
          {{ item.saleEndAt | formatDateTime }}
        </template>

        <template v-slot:item.action="{item}">
          <div class="d-flex">
            <v-btn @click="openEdit(item.id)" rounded icon class="t-btn--prm">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>
    </Table>
    <PointExchangeProductCreateUpdateDialog v-model="createUpdateDialog" :id="updateId" :checkPerUser="checkPerUser" @reload="reload"  />
  </div>
</template>

<script>
import Table from '@/components/Table/index.vue';
import SpanHoverPop from '../../components/shared/SpanHoverPop.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions'
import PointExchangeProductCreateUpdateDialog from './PointExchangeProductCreateUpdateDialog.vue';

export default {
  components: {Table, SpanHoverPop, PointExchangeProductCreateUpdateDialog},
  data( ) {
    return {
      items: [],
      showNotOnSale: false,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      createUpdateDialog: false,
      updateId: null,
    }
  },
  computed: {
    total() {
      return this.items?.length ?? 0
    },
    headers() {
      return [
        { text: '', value: 'action', align:'center', sortable: false},
        { text: 'ID', value: 'id', align:'center', sortable: false},
        { text: '名称', value: 'name', align:'center', sortable: false},
        { text: '会員Web', value: 'showMember', align:'center', sortable: false},
        { text: 'TVP', value: 'tvp', align:'center', sortable: false},
        { text: 'SP', value: 'sp', align:'center', sortable: false},
        { text: 'FP', value: 'fp', align:'center', sortable: false},
        { text: 'メール宛先', value: 'emailAddress', align:'center', sortable: false},
        { text: '会社名', value: 'companyName', align:'center', sortable: false},
        { text: '交換開始日時', value: 'startAt', align:'center', sortable: false},
        { text: '交換終了日時', value: 'endAt', align:'center', sortable: false},
      ]
    }
  },
  watch : {
    showNotOnSale: {
      handler() {
        this.reload();
      }
    }
  },
  mounted() {
    this.$refs.table.reset();
  },
  methods: {
    openEdit(id) {
      this.updateId = id;
      this.createUpdateDialog = true;
    },
    openCreate() {
      this.updateId = null;
      this.createUpdateDialog = true;
    },
    async getData() {
      await this.reload();
    },
    async reload() {
      const result = await this.$store.dispatch('pointExchangeProductList', {
        onSale: !this.showNotOnSale
      })
      this.items = result;
    }
  }
}
</script>

<style>

</style>